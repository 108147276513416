import React, { FunctionComponent } from 'react';
import PropTypes from 'prop-types';
import { MediaButtonProps } from './MediaButtonProps';
import { MediaButtonClasses } from './MediaButtonClasses';
import { Constants } from '../../../../core/constants';

import './MediaButton.scss';

const MediaButton: FunctionComponent<MediaButtonProps> = ({
    children,
    disabled,
    highlighted,
    labelPostion,
    type,
}): JSX.Element => {
    const selectButtonClass = () => {
        if (
            disabled &&
            highlighted &&
            (type === MediaButtonClasses.PLAY || type === MediaButtonClasses.PAUSE)
        ) {
            return 'unavailable-selected';
        }
        if (disabled) {
            return 'unavailable';
        }
        return '';
    };

    return (
        <div className="media-button" data-testid={`media-button-${type}`}>
            <img className={`${type} ${selectButtonClass()} ${highlighted ? 'selected' : ''}`} />
            {highlighted ? (
                <div
                    className={`button-name ${labelPostion ? `${labelPostion}` : ''}`}
                    style={
                        type === MediaButtonClasses.PLAY || type === MediaButtonClasses.PAUSE
                            ? { marginLeft: `${Constants.PLAY_PAUSE_BUTTON_LEFT_ALIGNMENT}px` }
                            : {}
                    }
                >
                    {children}
                </div>
            ) : null}
        </div>
    );
};

MediaButton.propTypes = {
    children: PropTypes.string,
    disabled: PropTypes.bool,
    highlighted: PropTypes.bool,
    labelPostion: PropTypes.string,
    type: PropTypes.string.isRequired,
};

export default MediaButton;
